import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	background-color: black;
`;

export const Ema = () => {
	return (
		<Wrapper>
			<iframe
				title="Home Assistant"
				src="https://mowebim.duckdns.org/share/Au7MuFF1hNti0bIKOwycxJXiaM33rE9wmIs17k2L4qgSR7bMnJF-FOleQ8J9sBnOBEc"
			/>
		</Wrapper>
	);
};
