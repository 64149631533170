import React from "react";

import "./PortfolioItem.scss";

const PortfolioItem = (props) => {
  const { title, image, webp } = props;

  return (
    <div className={"portfolio-item card shadow"}>
      <a
        href={"//" + title}
        title={title}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="portfolio-item-image">
          <picture>
            <source srcset={webp} type="image/webp" />
            <source srcset={image} type="image/jpeg" />
            <img src={image} className="img-fluid" alt={title} />
          </picture>
        </div>
        <div className="portfolio-item-overlay">
          <h4>{title}</h4>
        </div>
      </a>
    </div>
  );
};

export default PortfolioItem;
