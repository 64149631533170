import React from "react";

import heroImage from "../../Assets/images/hero.jpg";
import heroImageWebp from "../../Assets/images/hero.webp";

import "./Hero.scss";

const Hero = (props) => {
  return (
    <section id="hero">
      <picture>
        <source srcset={heroImageWebp} />
        <source srcset={heroImage} />
        <img src={heroImage} alt="Morosan Mihai" />
      </picture>

      <div className="heroOverlay">
        <div className="heroContent">
          <h1>Web Developer</h1>
          <p>
            Over the last 7 years of experience I'm certain that there is no
            challenge I can't surpass. I'm looking to grow, perfect my skills
            and for that I'm open to new challenges.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
