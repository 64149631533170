import React from "react";
import About from "../components/About/About";
import Contact from "../components/Contact/Contact";
import Header from "../components/Header/Header";
import Hero from "../components/Hero/Hero";
import Portfolio from "../components/Portfolio/Portfolio";

export const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <Portfolio />
      <About />
      <Contact />
    </>
  );
};
