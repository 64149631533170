import React from "react";

import "./About.scss";

import html from "../../Assets/images/html.svg";
import css from "../../Assets/images/css.svg";
import js from "../../Assets/images/js.svg";
import react from "../../Assets/images/react.svg";
import sass from "../../Assets/images/sass.svg";
import woo from "../../Assets/images/woo.svg";
import wp from "../../Assets/images/wp.svg";
import opencart from "../../Assets/images/opencart.svg";
import php from "../../Assets/images/php.svg";

const About = (props) => {
  return (
    <section id="about">
      <div className="container">
        <h2 className="text-center">Hi there!</h2>
        <p>
          I'm Mihai, a Web Developer with over 7 years of experience. I can
          offer you pixel perfect services in dektop and responsize
          mobile-first. Feel free to reach out to me so we can start planning
          your next project.
        </p>

        <div className="technologies d-flex justify-content-center align-items-center flex-wrap">
          <img src={html} alt="" />
          <img src={css} alt="" />
          <img src={js} alt="" />
          <img src={wp} alt="" />
          <img src={woo} alt="" />
          <img src={opencart} alt="" />
          <img src={sass} alt="" />
          <img src={php} alt="" />
          <img src={react} alt="" />
        </div>
      </div>
    </section>
  );
};

export default About;
