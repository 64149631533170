import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./Pages/Home";
import { Ha } from "./Pages/Ha";
import { Ema } from "./Pages/Ema";

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/ha" element={<Ha />} errorElement={<Home />} />

      <Route path="/ema" element={<Ema />} errorElement={<Home />} />

        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} errorElement={<Home />} />


      </Routes>
    </BrowserRouter>
  );
}

export default App;
