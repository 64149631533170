import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-scroll";

import logo from "../../Assets/images/logo.svg";

import "./Header.scss";

const Header = (props) => {
  return (
    <header>
      <Navbar collapseOnSelect expand="xl" variant="dark">
        <div className="container">
          <Navbar.Brand href="/">
            <img src={logo} alt="Mihai Morosan - moweb" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              <Link className="nav-link" smooth={true} to="portfolio">
                Work
              </Link>
              <Link className="nav-link" smooth={true} to="about">
                About
              </Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  );
};

export default Header;
