import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

import "./Contact.scss";

const Contact = (props) => {
  return (
    <footer>
      <div className="text-center">
        <a
          href="https://www.linkedin.com/in/mihai-morosan-a855934a"
          title="Linkedin profile"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a
          href="https://drive.google.com/file/d/1G8KjCoF47eYQhq8Lacl5uOpE7wFzkbFJ/view?usp=sharing"
          title="Mihai Morosan CV"
          target="_blank"
          rel="noopener noreferrer"
        >
          Check out my <strong>CV</strong>
        </a>
      </div>
    </footer>
  );
};

export default Contact;
