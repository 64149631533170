import React, { useState } from "react";
import PortfolioItem from "../PortfolioItem/PortfolioItem";
import Fade from "react-reveal/Fade";

import "./Portfolio.scss";

import esensis from "../../Assets/images/portfolio/esensis-min.jpg";
import pensiicluj from "../../Assets/images/portfolio/pensiicluj-min.jpg";
import avrexpert from "../../Assets/images/portfolio/avrexpert-min.jpg";
import covoare from "../../Assets/images/portfolio/covoare-min.jpg";
import gnc from "../../Assets/images/portfolio/gnc-min.jpg";
import gofinancial from "../../Assets/images/portfolio/gofinancial-min.jpg";
import inreach from "../../Assets/images/portfolio/inreach-min.jpg";
import karting from "../../Assets/images/portfolio/karting-min.jpg";
import lawyer from "../../Assets/images/portfolio/lawyer-min.jpg";
import magazinpentrucopii from "../../Assets/images/portfolio/magazinpentrucopii-min.jpg";
import parbrize from "../../Assets/images/portfolio/parbrize-min.jpg";
import salvamont from "../../Assets/images/portfolio/salvamont-min.jpg";
import savuracing from "../../Assets/images/portfolio/savuracing-min.jpg";
import sdstudio from "../../Assets/images/portfolio/sdstudio-min.jpg";
import skaleweb from "../../Assets/images/portfolio/skaleweb-min.jpg";
import stofegadd from "../../Assets/images/portfolio/stofegadd-min.jpg";
import turenhout from "../../Assets/images/portfolio/turenhout-min.jpg";
import useit from "../../Assets/images/portfolio/useit-min.jpg";
import custoza from "../../Assets/images/portfolio/custoza-min.jpg";
import velemia from "../../Assets/images/portfolio/velemia-min.jpg";

import esensiswebp from "../../Assets/images/portfolio/esensis-min.webp";
import pensiiclujwebp from "../../Assets/images/portfolio/pensiicluj-min.webp";
import avrexpertwebp from "../../Assets/images/portfolio/avrexpert-min.webp";
import covoarewebp from "../../Assets/images/portfolio/covoare-min.webp";
import gncwebp from "../../Assets/images/portfolio/gnc-min.webp";
import gofinancialwebp from "../../Assets/images/portfolio/gofinancial-min.webp";
import inreachwebp from "../../Assets/images/portfolio/inreach-min.webp";
import kartingwebp from "../../Assets/images/portfolio/karting-min.webp";
import lawyerwebp from "../../Assets/images/portfolio/lawyer-min.webp";
import magazinpentrucopiiwebp from "../../Assets/images/portfolio/magazinpentrucopii-min.webp";
import parbrizewebp from "../../Assets/images/portfolio/parbrize-min.webp";
import salvamontwebp from "../../Assets/images/portfolio/salvamont-min.webp";
import savuracingwebp from "../../Assets/images/portfolio/savuracing-min.webp";
import sdstudiowebp from "../../Assets/images/portfolio/sdstudio-min.webp";
import skalewebwebp from "../../Assets/images/portfolio/skaleweb-min.webp";
import stofegaddwebp from "../../Assets/images/portfolio/stofegadd-min.webp";
import turenhoutwebp from "../../Assets/images/portfolio/turenhout-min.webp";
import useitwebp from "../../Assets/images/portfolio/useit-min.webp";
import custozawebp from "../../Assets/images/portfolio/custoza-min.webp";
import velemiawebp from "../../Assets/images/portfolio/velemia-min.webp";

const Portfolio = (props) => {
  const [shown, setShown] = useState(false);

  const portfolioItems = [
    { title: "Esensis.ro", description: "", image: esensis, webp: esensiswebp },
    { title: "Custoza.ro", description: "", image: custoza, webp: custozawebp },
    {
      title: "Pensiicluj.ro",
      description: "",
      image: pensiicluj,
      webp: pensiiclujwebp,
    },
    {
      title: "Parbrize.ro",
      description: "",
      image: parbrize,
      webp: parbrizewebp,
    },
    {
      title: "Sdstudio.eu",
      description: "",
      image: sdstudio,
      webp: sdstudiowebp,
    },
    {
      title: "Skaleweb.ro",
      description: "",
      image: skaleweb,
      webp: skalewebwebp,
    },
    { title: "Useit.nl", description: "", image: useit, webp: useitwebp },
    {
      title: "Covoareexclusive.ro",
      description: "",
      image: covoare,
      webp: covoarewebp,
    },
    {
      title: "Gnc-automatizari.ro",
      description: "",
      image: gnc,
      webp: gncwebp,
    },
    {
      title: "Gofinanciallease.nl",
      description: "",
      image: gofinancial,
      webp: gofinancialwebp,
    },
    {
      title: "Horatiusavu.com",
      description: "",
      image: savuracing,
      webp: savuracingwebp,
    },
    {
      title: "Savuracing.com",
      description: "",
      image: karting,
      webp: kartingwebp,
    },
    {
      title: "Salvamontcluj.ro",
      description: "",
      image: salvamont,
      webp: salvamontwebp,
    },
    {
      title: "Turenhout.eu",
      description: "",
      image: turenhout,
      webp: turenhoutwebp,
    },
    {
      title: "Stofegadd.ro",
      description: "",
      image: stofegadd,
      webp: stofegaddwebp,
    },
    {
      title: "Inreachstrategy.nl",
      description: "",
      image: inreach,
      webp: inreachwebp,
    },
    {
      title: "Avrexpert.ro",
      description: "",
      image: avrexpert,
      webp: avrexpertwebp,
    },
    {
      title: "Magazinpentrucopii.ro",
      description: "",
      image: magazinpentrucopii,
      webp: magazinpentrucopiiwebp,
    },
    {
      title: "Lawyer-in-romania.com",
      description: "",
      image: lawyer,
      webp: lawyerwebp,
    },
    { title: "Velemia.ro", description: "", image: velemia, webp: velemiawebp },
  ];

  const portfolioItemsElStart = portfolioItems.slice(0, 8).map((item, i) => {
    return (
      <div key={i} className="col-lg-3 col-md-6 col-sm-6">
        <PortfolioItem {...item} />
      </div>
    );
  });
  const portfolioItemsElEnd = portfolioItems.slice(8).map((item, i) => {
    return (
      <div key={i} className="col-lg-3 col-md-6 col-sm-6">
        <PortfolioItem {...item} />
      </div>
    );
  });

  return (
    <section id="portfolio">
      <div className="container">
        <h3>Some of my work so far...</h3>
        <Fade bottom cascade>
          <div className="row">{portfolioItemsElStart}</div>
        </Fade>
        <Fade bottom cascade collapse when={shown}>
          <div className="row">{portfolioItemsElEnd}</div>
        </Fade>

        {shown === false ? (
          <div className="d-block text-center">
            <div className="btn btn-secondary" onClick={() => setShown(true)}>
              Show more
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default Portfolio;
